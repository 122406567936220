import colors from "@barracuda/bds-core/dist/styles/theme_base_mui/colors";

/*
* To override settings in CudaReact, put an object in the overrides object with the name
* CR<name_that_you_pass_to_the_overrideableStyles_function>
*/
export default {
    palette: {
        background: {
            appbar: colors.bgNetworkSecurity
        },
        text: {
            primary: '#191919',
            secondary: '#646464',
            disabled: '#AFAFAE',
            hint: '#AFAFAE',
        },
        secondary: {
            dark: '#4B4B4B',
            main: '#646464',
            light: '#7D7D7D',
            // @ts-ignore
            background: '#F7F7F7',
            border: '#AFAFAE',
            contrastText: '#FFFFFF',
        },
        primary: {
            dark: '#0A5D91',
            main: '#067CC1',
            light: '#2F92CE',
            // @ts-ignore
            background: '#E4F4FF',
            border: '#7CBDE6',
            contrastText: '#FFFFFF',
        },
    },
    overrides: {
        CRAppLayout: {
            main: {
                minWidth: 720
            }
        },
        MuiButton: {
            containedSizeSmall: {
                padding: "2px 10px"
            }
        }
    },
    props: {
        MuiIconButton: {
            size: "small"
        }
    }
};